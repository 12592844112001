import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaTiktok } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="footer">
            <div className='footer-container'>
                <div className="footer-section footer-contact-info">
                    <h3>Мы на связи с 10:00 до 21:00!</h3>
                    <p>Любые товары в рассрочку!</p>
                    <a href='tel:+375293409071'>+375 (29) 340-90-71</a>
                    <a href='tel:+375293789356'>+375 (29) 378-93-56</a>
                    <a href='tel:+375293416810'>+375 (29) 341-68-10</a>
                </div>
                <div className="footer-section footer-nav">
                    <h3>Полезные ссылки</h3>
                    <Link to="/payment">Оплата</Link>
                    <Link to="/delivery">Доставка</Link>
                    <Link to="/installments">Рассрочка</Link>
                    <Link to="/privacy-policy">Политика конфиденциальности</Link>
                </div>
                <div className="footer-section footer-info">
                    <h3>Информация</h3>
                    <Link to="/public-offer">Публичная оферта</Link>
                    <Link to="/">Гарантия</Link>
                    <Link to="/about">О компании</Link>
                    <Link to="/contacts">Контакты</Link>
                </div>
                <div className="footer-section footer-subscribe">
                    <h3>Подписывайтесь на нас!</h3>
                    <div className="footer-social-icons">
                        <a href='https://www.instagram.com/citrus.mall/' target="_blank" rel="noopener noreferrer">
                            <FaInstagram />
                        </a>
                        <a href='https://www.tiktok.com/@citrusmall.by' target="_blank" rel="noopener noreferrer">
                            <FaTiktok />
                        </a>
                    </div>
                </div>
            </div>
            <div className='footer-info-dev'>
                <p>Общество с ограниченной ответственностью "ЦИТРУСКОМ"<br/>
                Зарегистрирован в Мингорисполкоме 25.07.2023 № 193699866<br/>
                Контакты лица, уполномоченного рассматривать обращения покупателей о нарушении их прав: Здоровцова Я.И. +375-29-341-68-10, e-mail: cirtusbuh@gmail.com<br/>
                Номер телефона работников местных исполнительных и распорядительных органов по месту государственной регистрации ООО «ЦИТРУСКОМ», уполномоченных рассматривать обращения покупателей: +375-17-378-34-12 - администрация Советского района г. Минска.<br/>
                В торговом реестре с 12.11.2024 № регистрации 733443</p>
            </div>
        </footer>
    );
};

export default Footer;
