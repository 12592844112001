import React, { useState, useEffect, useRef } from 'react';
import '../Styles/components/Modal.css';
import InstallmentCalculator from './math/InstallmentCalculator';
import Image512 from '../images/512.png';
import { FaCheckCircle } from 'react-icons/fa';
import OrderService from './services/OrderService';
import { FormControl, FormHelperText, Link, Typography, Checkbox } from '@mui/joy';

const Modal = ({ isOpen, onClose, product, hasInstallment, installmentMonths }) => {
    const overlayRef = useRef(null);
    const contentRef = useRef(null);

    const [isOrderVisible, setIsOrderVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const [imageSrc, setImageSrc] = useState(product.sourceImage);
    const [minInstallment, setMinInstallment] = useState(null);
    const [installment, setInstallment] = useState(null);
    const [value, setValue] = useState(12);
    const values = [12, 18, 24, 36, 48];

    const [userPhone, setUserPhone] = useState('');
    const [userAddress, setUserAddress] = useState('');
    const [userName, setUserName] = useState('');

    const [policy, setPolicy] = useState(false);

    const handlePolicyChange = (event) => {
        setPolicy(event.target.checked);
    };

    const updateValue = (event) => {
        const sliderValue = parseInt(event.target.value, 10);
        const selectedValue = values[sliderValue];
        setValue(selectedValue);
        const calculatedInstallment = InstallmentCalculator.calculateInstallment(product.price, selectedValue);
        setInstallment(calculatedInstallment);
    };

    useEffect(() => {
        const calculatedInstallment = InstallmentCalculator.calculateInstallment(product.price, 48);
        setMinInstallment(calculatedInstallment);
    }, [product.price]);

    useEffect(() => {
        const calculatedInstallment = InstallmentCalculator.calculateInstallment(product.price, 12);
        setInstallment(calculatedInstallment);
    }, [product.price]);

    useEffect(() => {
        if (isOpen) {
            setIsOrderVisible(false);
            setIsLoading(false);
            setIsSuccess(false);

            const handleEscape = (event) => {
                if (event.key === 'Escape') {
                    handleClose();
                }
            };

            document.addEventListener('keydown', handleEscape);
            return () => {
                document.removeEventListener('keydown', handleEscape);
            };
        }
    }, [isOpen]);

    const callOrder = async (event) => {
        event.preventDefault();
        if(!policy)
        {
            alert('Сперва примите нашу политику конфиденциальности.');
            return;
        }

        setIsLoading(true);

        try {
            await OrderService.createOrder(userPhone, userName, userAddress, product.id, hasInstallment ? hasInstallment : installment, `${installmentMonths ? installmentMonths : value} мес.`);
            setIsSuccess(true);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
            setIsOrderVisible(true);
        }
    };

    const handleClose = () => {
        const overlay = overlayRef.current;
        const content = contentRef.current;

        if (overlay && content) {
            overlay.style.animation = 'fadeOut 0.3s forwards';
            content.style.animation = window.innerWidth <= 768 ? 'slideOut 0.3s forwards' : 'scaleOut 0.3s forwards';

            setTimeout(() => {
                onClose();
            }, 300);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" ref={overlayRef}>
            <div className="modal-content" ref={contentRef} onClick={(e) => e.stopPropagation()}>
                <button className="modal-close" onClick={handleClose}>×</button>
                {isLoading ? (
                    <div className='progress-container'>
                        <div className="progress-loader">
                            <svg className="progress-circular">
                                <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="5" strokeMiterlimit="10"></circle>
                            </svg>
                        </div>
                    </div>
                ) : isOrderVisible ? (
                    isSuccess ? (
                        <div id='orderSuccess'>
                            <h2 className='modal-h2'>Успешно!</h2>
                            <hr />
                            <div className="success-container">
                                <div className="success-icon">
                                    <FaCheckCircle />
                                </div>
                                <h2>Заказ оформлен!</h2>
                                <div className='success-button'>
                                    <button onClick={handleClose} className='button-modal'>Вернуться на сайт</button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div id='makeOrder'>
                            <h2 className='modal-h2'>Ваш заказ:</h2>
                            <hr />
                            <div className="order-item">
                                <div className="modal_container">
                                    <div className="modal_image">
                                        <img src={imageSrc} onError={() => setImageSrc(Image512)} alt="Product" />
                                    </div>
                                    <div className="modal_text">
                                        <p className="modal_text_main">{product.name}</p>
                                        {!hasInstallment ? (
                                            <p className="modal_text_price">от {minInstallment} руб. в мес.</p>
                                        ) : (
                                            <p className="modal_text_price">Рассрочка на {installmentMonths} мес.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <p className='text'>Оформление заказа</p>
                            <form onSubmit={callOrder}>
                                <input className='input-modal' type="text" id="nameInput" name="nameInput" onChange={(e) => setUserName(e.target.value)} placeholder="Ваше имя" required />
                                <input className='input-modal' type="text" id="phoneInput" name="phoneInput" onChange={(e) => setUserPhone(e.target.value)} placeholder="Номер телефона" required />
                                <input className='input-modal' type="text" id="addressInput" name="addressInput" onChange={(e) => setUserAddress(e.target.value)} placeholder="Адрес доставки" required />
                                {!hasInstallment && (
                                    <>
                                        <p className='text'>Среднемесячный платеж</p>
                                        <div className="payment-term-container">
                                            <div className="payment-term">
                                                <input
                                                    className="sliderInput"
                                                    type="range"
                                                    min="0"
                                                    max={values.length - 1}
                                                    step="1"
                                                    value={values.indexOf(value)}
                                                    onChange={updateValue}
                                                />
                                                <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 2, marginRight: 0, marginTop: 8 }}>
                                                    {values.map((val) => (
                                                        <div key={val}>{val}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <p className="payment-amount">Итоговая сумма: {hasInstallment ? hasInstallment : installment} руб. в мес.</p>
                                <button type="submit" className='button-modal'>Отправить заявку</button>
                            </form>
                        </div>
                    )
                ) : (
                    <div id='makeOrder'>
                        <h2 className='modal-h2'>Ваш заказ:</h2>
                        <hr />
                        <div className="order-item">
                            <div className="modal_container">
                                <div className="modal_image">
                                    <img src={imageSrc} onError={() => setImageSrc(Image512)} alt="Product" />
                                </div>
                                <div className="modal_text">
                                    <p className="modal_text_main">{product.name}</p>
                                    {!hasInstallment ? (
                                        <p className="modal_text_price">от {minInstallment} руб. в мес.</p>
                                    ) : (
                                        <p className="modal_text_price">Рассрочка на {installmentMonths} мес.</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <hr />
                        <p className='text'>Оформление заказа</p>
                        <form onSubmit={callOrder}>
                            <input className='input-modal' type="text" id="nameInput" name="nameInput" onChange={(e) => setUserName(e.target.value)} placeholder="Ваше имя" required />
                            <input className='input-modal' type="text" id="phoneInput" name="phoneInput" onChange={(e) => setUserPhone(e.target.value)} placeholder="Номер телефона" required />
                            <input className='input-modal' type="text" id="addressInput" name="addressInput" onChange={(e) => setUserAddress(e.target.value)} placeholder="Адрес доставки" required />
                            {!hasInstallment && (
                                <>
                                    <p className='text'>Среднемесячный платеж</p>
                                    <div className="payment-term-container">
                                        <div className="payment-term">
                                            <input
                                                className="sliderInput"
                                                type="range"
                                                min="0"
                                                max={values.length - 1}
                                                step="1"
                                                value={values.indexOf(value)}
                                                onChange={updateValue}
                                            />
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 2, marginRight: 0, marginTop: 8 }}>
                                                {values.map((val) => (
                                                    <div key={val}>{val}</div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <p className="payment-amount">Итоговая сумма: {hasInstallment ? hasInstallment : installment} руб. в мес.</p>
                            <FormControl size="sm" sx={{ width: '100%', mb: 1 }}>
                                <Checkbox
                                    color='warning'
                                    checked={policy}
                                    onChange={handlePolicyChange}
                                    label={
                                        <React.Fragment>
                                            <Typography>Соглашаюсь с условиями</Typography>{' '}
                                            <Typography sx={{ fontWeight: 'md' }}>Публичной оферты</Typography> и <Typography sx={{ fontWeight: 'md' }}>Политикой конфиденциальности</Typography>.
                                        </React.Fragment>
                                    }
                                />
                                <FormHelperText>
                                    <Typography level="body-sm">
                                        Прочитайте нашу <Link href="/public-offer">Публичную оферту</Link> и <Link href="/privacy-policy">Политику конфиденциальности</Link>.
                                    </Typography>
                                </FormHelperText>
                            </FormControl>
                            <button type="submit" className='button-modal'>Отправить заявку</button>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Modal;
