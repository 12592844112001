import React from 'react';
import { useEffect } from 'react';

const PublicOffer = () => {
    useEffect(() => {
        document.title = 'Цитрусмолл — Договор публичной оферты';
    }, []);

    return <div className="page">
        <section>
            <div>
                <h2 style={{ textAlign: 'center' }}>ПУБЛИЧНАЯ ОФЕРТА<br />интернет-магазина citrusmall.by</h2>

                <h3>
                    1.<span >&#xa0; </span>Термины<span > </span>и<span > </span><span >определения</span>
                </h3>

                <p class="BodyText" >
                    <strong>Заказ </strong>– акцепт Покупателя, направленный Продавцу<span > </span>путём нажатия на сайте кнопки «Отправить заявку», а также посредством телефонной, почтовой, электронной или иной связи, в том числе посредством использования сети Интернет.
                </p>
                <p class="BodyText" >
                    <strong>Интернет-магазин </strong>– информационный ресурс Продавца в глобальной компьютерной сети Интернет<span > </span>(сайт), позволяющий<span > </span>выбрать и<span > </span>осуществить заказ<span > </span>на<span > </span>покупку<span > </span>товаров Продавца<span > </span>без<span > </span>торгового <span >объекта.</span>
                </p>
                <p>
                    <strong>Сайт</strong><strong><span > </span></strong><strong>интернет-магазина:</strong><strong><span > </span></strong><a href="https://citrusmall.by/" ><u><span >https://citrusmall.by/</span></u><span >.</span></a>
                </p>
                <p class="BodyText" >
                    <strong>Покупатель </strong>– физическое лицо, обладающее достаточной дееспособностью для совершения сделки и имеющее намерение заказать или приобрести либо заказывающее, приобретающее товар.
                </p>
                <p>
                    <strong>Владелец интернет-магазина, Продавец </strong>– ООО "Цитруском", 220013, г. Минск, ул. 2-я Шестая линия, д. 11, каб. 6а-8-120.
                </p>
                <p class="BodyText" >
                    <strong>Товар </strong>– имущество (предмет договора купли-продажи, заключаемого между Покупателем и Продавцом), информация о котором размещена на сайте Интернет-магазина и предназначенное для <span >продажи.</span>
                </p>
                <p class="BodyText" >
                    <strong>Договор </strong>– соглашение сторон о купле-продаже Товаров в Интернет-магазине, заключённое<span > </span>путем Акцепта Оферты.
                </p>
                <p class="BodyText" >
                    &#xa0;
                </p>
                <h3>
                    2.<span >&#xa0; </span>Предмет<span > </span>публичной<span > </span><span >оферты</span>
                </h3>

                <p class="ListParagraph" >
                    <span>2.1.</span><span >&#xa0;&#xa0;&#xa0;&#xa0; </span>Настоящая публичная оферта (далее – Оферта) является официальным предложением Продавца неопределенному кругу лиц к заключению договора розничной купли-продажи товара (далее<span > </span>– Договор) на условиях, указанных на Сайте интернет-магазина (далее – Сайт) и определённых в <span >Оферте.</span>
                </p>
                <p class="ListParagraph" >
                    <span>2.2.</span><span >&#xa0; </span>Договор<span > </span>является<span > </span>договором<span > </span>присоединения и<span > </span>заключается<span > </span>путём присоединения<span > </span>Покупателя к предложенным Продавцом условиям Договора, изложенным в Оферте и на Сайте, в целом (без оговорок и дополнений).
                </p>
                <p class="ListParagraph" >
                    <span>2.3.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Условия продажи Товара и Договора, заключаемого Продавцом и Покупателем, определяются Продавцом. Продавец вправе в одностороннем порядке менять условия Оферты, в том числе способы и сроки оплаты и доставки товара, а также цены на товары. Все изменения доводятся до сведения Покупателя путём их размещения на Сайте.
                </p>
                <p class="ListParagraph" >
                    <span>2.4.</span><span >&#xa0;&#xa0; </span>Публикация информации на Сайте, очевидно не соответствующей товару, в том числе цены, фото и описания товара, признается технической ошибкой. При возникновении технической ошибки Продавец вправе отказать Покупателю в заключении Договора и аннулировать Заказ.
                </p>
                <p class="ListParagraph" >
                    <span>2.5.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Договор считается заключенным в момент осуществления Заказа Покупателя. Заказ Покупателя считается полученным Продавцом с момента направления Продавцом Покупателю электронного сообщения и (или) телефонного звонка Продавца Покупателю, подтверждающего заключение Договора и содержащего существенные условия этого Договора, а также номер Заказа или иной способ идентификации Заказа, который позволяет Покупателю получить информацию о заключенном Договоре и его условиях.
                </p>
            </div>
            <br />
            <div>
                <p class="ListParagraph" >
                    <span>2.6.</span><span >&#xa0; </span>Покупатель обязуется до момента<span > </span>оформления Заказа<span > </span>и<span > </span>заключения Договора<span > </span>ознакомиться с содержанием и условиями Оферты, информацией о товаре (свойствах, характеристиках и т.д.) в Интернет-магазине и ценами на него.
                </p>
                <p class="ListParagraph" >
                    <span>2.7.</span><span >&#xa0;&#xa0;&#xa0;&#xa0; </span>Правоотношения Продавца и Покупателя регулируются законодательством Республики Беларусь, в том числе положениями Гражданского <span >кодекса</span> Республики Беларусь, <span >Закона</span> Республики Беларусь от 09.01.2002 № 90-З «О защите прав потребителей», законодательством о торговле, в том числе <span >Правилами</span> продажи товаров при осуществлении дистанционной торговли, утвержденными постановлением Совета Министров Республики Беларусь от 15.01.2009 № 31.
                </p>
                <p class="BodyText" >
                    &#xa0;
                </p>
                <p class="BodyText" >
                    &#xa0;
                </p>
                <h3 >
                    3.<span >&#xa0; </span>Оформление<span > </span><span >Заказа</span>
                </h3>

                <p class="ListParagraph" >
                    <span >3.1.</span><span >&#xa0; </span><span >Оформление</span><span > </span><span >Заказа</span><span > </span><span >Товара</span><span > </span><span >в</span><span > </span><span >Интернет-магазине</span><span > </span><span >может</span><span > </span><span >производиться</span><span > </span><span >Покупателем:</span>
                </p>
                <p class="ListParagraph" >
                    <span >-</span><span >&#xa0; </span><span >через</span><span > </span><span >Сайт;</span>
                </p>
                <p class="ListParagraph" >
                    <span>-</span><span >&#xa0; </span>иными доступными способами, а именно: посредством телефонной, почтовой, электронной<span > </span>или иной связи.
                </p>
                <p class="ListParagraph" >
                    <span>3.2.</span><span >&#xa0;&#xa0;&#xa0;&#xa0; </span>Оформляя Заказ Товара, Покупатель принимает и безоговорочно соглашается со всеми условиями, изложенными в Договоре, а также с информацией, размещенной в Интернет-магазине в момент подтверждения Заказа и считается проинформированным о ценах, свойствах и характеристиках <span >Товара.</span>
                </p>
                <p class="ListParagraph" >
                    <span>3.3.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Стороны пришли к соглашению, что иная информация, предусмотренная Законом Республики Беларусь от 09.01.2002 № 90-З «О защите прав потребителей», которую Продавец обязан довести до сведения Покупателя до заключения Договора, предоставляется только по требованию Покупателя в устной форме или путем обмена электронными сообщениями, что признается Сторонами письменной формой. Покупатель подтверждает, что с момента оформления им Заказа он получил всю необходимую и полную информацию в соответствии с требованиями Закона Республики Беларусь от 09.01.2002 № 90-З «О защите прав потребителей», позволяющую осуществить Заказ на приобретение Товаров в Интернет-магазине Продавца.
                </p>
                <p class="ListParagraph" >
                    <span>3.4.</span><span >&#xa0;&#xa0;&#xa0;&#xa0; </span>При оформлении Заказа по телефону Покупатель предоставляет Продавцу следующую необходимую информацию для оформления Заказа: желаемый товар, его количество, способ и адрес доставки Товара (из предложенных), контактный телефон, имя, желаемую дату и время получения Заказа (из предложенных), способы оплаты (в том числе приобретение в кредит или лизинг), иную информацию для оформления и доставки Заказа при необходимости.
                </p>
                <p class="ListParagraph" >
                    <span>3.5.</span><span >&#xa0;&#xa0;&#xa0; </span>При оформлении Заказа через Сайт Покупатель заполняет электронную форму Заказа на Товар и отправляет сформированный Заказ Продавцу посредством сети Интернет. После получения Заказа Продавец согласовывает с Покупателем по контактному телефону способ и адрес доставки Товара<span > </span>(из<span > </span>предложенных),<span > </span>желаемую<span > </span>дату<span > </span>и<span > </span>время<span > </span>получения<span > </span>Заказа<span > </span>(из предложенных), способ<span > </span>оплаты (в том числе приобретение Товара в кредит или лизинг), иную информацию для оформления<span > </span>и доставки заказа при необходимости.
                </p>
                <p class="ListParagraph" >
                    <span>3.6.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>В случае возникновения у Покупателя вопросов, касающихся описания, свойств и характеристик Товара, перед оформлением Заказа Покупатель обязан обратиться к Продавцу по указанному телефону или воспользоваться формой обратной связи в Интернет-магазине Продавца.
                </p>
                <p class="ListParagraph" >
                    <span>3.7.</span><span >&#xa0;&#xa0;&#xa0;&#xa0; </span>В случае отсутствия необходимого Товара на складе Продавца или необходимого его количества, Продавец информирует об этом Покупателя по контактному телефону, отраженному в Заказе. При отсутствии необходимого Товара на складе Продавца Покупатель вправе согласовать переоформление Заказа на иной Товар либо отказаться от Заказа (аннулировать Заказ) полностью или в <span >части.</span>
                </p>
                <p class="ListParagraph" >
                    <span>3.8.</span><span >&#xa0; </span>Покупатель понимает и соглашается, что в связи с разными техническими характеристиками устройств, с которых возможно просматривать Сайт, цвета на изображениях Товаров могут отличаться от цветовой гаммы оригинала Товара.
                </p>
            </div>
            <br />
            <div>
                <h3 >
                    4.<span >&#xa0; </span><span >Конфиденциальность</span>
                </h3>
                <p class="ListParagraph" >
                    <span>4.1.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Информация, предоставленная Покупателем, является конфиденциальной. Оформление Заказа означает согласие и разрешение Покупателя Продавцу обрабатывать предоставленные персональные данные Покупателя.
                </p>
                <p class="ListParagraph" >
                    <span>4.2.</span><span >&#xa0;&#xa0;&#xa0;&#xa0; </span>Обработка персональных данных Покупателя осуществляется в порядке, установленном действующим законодательством<span > </span>в<span > </span>отношении<span > </span>обработки персональных<span > </span>данных, а<span > </span>также<span > </span>в соответствии с Политикой обработки персональных данных ООО «ЦИТРУСКОМ».
                </p>
                <p class="ListParagraph" >
                    <span>4.3.</span><span >&#xa0;&#xa0; </span>Оставляя предоставленные данные, Покупатель подтверждает тем самым своё согласие на получение электронных писем, сообщений, рассылок, уведомлений направляемых посредством телефонной связи, сети Интернет, в т.ч. посредством мессенджеров, содержащих информацию о новинках, акциях, специальных предложениях, уведомлениях в рамках исполнения договора и т.д. Покупатель вправе отказаться от получения сообщений, уведомив об этом Продавца.
                </p>
                <p class="ListParagraph" >
                    <span>4.4.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>В целях исполнения Договора Продавец вправе без дополнительного согласования с Покупателем осуществлять записи телефонных разговоров с Покупателем. Покупатель, оформляя Заказ или совершая (получая) вызов в адрес Продавца (в свой адрес), подтверждает, что проинформирован об этом и согласен с записью телефонного разговора.
                </p>
                <p class="BodyText" >
                    &#xa0;
                </p>
                <h3 >
                    5.<span >&#xa0; </span>Доставка<span > </span>и передача<span > </span>товара<span > </span><span >Покупателю</span>
                </h3>

                <p class="ListParagraph" >
                    <span >5.1.</span><span >&#xa0; </span><span >Порядок</span><span > </span><span >согласования</span><span > </span><span >условий</span><span > </span><span >доставки</span><span > </span><span >Товара:</span>
                </p>
                <p class="ListParagraph" >
                    <span ><span >5.1.1.</span></span><span >&#xa0; </span><span >Доставка</span><span > </span><span >Товара</span><span > </span><span >Покупателю</span><span > </span><span >осуществляется</span><span > </span><span >за</span><span > </span><span >счёт</span><span > Продавца.</span>
                </p>
                <p class="ListParagraph" >
                    <span >5.1.2.</span><span >&#xa0;&#xa0; </span>После оформления Заказа Продавец согласовывает с Покупателем дату и время доставки Товара (из предложенных).
                </p>
                <p class="ListParagraph" >
                    <span ><span >5.1.3.</span></span><span >&#xa0; </span><span >Покупатель</span><span > </span><span >обязан</span><span > </span><span >сообщить</span><span > </span><span >достоверный</span><span > </span><span >адрес</span><span > </span><span >доставки</span><span > </span><span >и</span><span > </span><span >телефон</span><span > </span><span >для</span><span > </span><span >связи.</span>
                </p>
                <p class="ListParagraph" >
                    <span>5.2.</span><span >&#xa0; </span>Покупатель обязан принять курьера по адресу<span > </span>и во время, согласованным с Продавцом. Если после прибытия курьера по согласованному адресу, указанному в Заказе, Покупатель не находится на месте и не отвечает на телефонные звонки в течение 10 минут, то производится повторная доставка в новые сроки, согласованные с Продавцом. Повторная доставка производится при условии возмещения Покупателем расходов, связанных с повторной доставкой товара. В противном случае Заказ подлежит аннулированию. В случае непринятия заказа по вине Покупателя все затраты, связанные с возвратом предоплаты, несет Покупатель.
                </p>
                <p class="ListParagraph" >
                    <span>5.3.</span><span >&#xa0;&#xa0; </span>При приёме Товара Покупатель обязан самостоятельно произвести проверку Товара. Курьер не обязан демонстрировать Товар и проводить его проверку. Проверка Товара должна производиться с сохранением товарного вида, а также с учётом характеристик Товара и места (внешних условий) его приёмки. Продолжительность такой проверки не должна превышать 10 минут. В случае обнаружения несоответствия Товара заявленным характеристикам, цене, способам оплаты и иным условиям Покупатель вправе отказаться от Товара. В случае внесения за Товар предоплаты, возврат денежных средств производится в порядке, предусмотренном п. 9 Договора.
                </p>
                <p class="ListParagraph" >
                    <span>5.4.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>После проверки Товара Покупатель должен расписаться в акте приёма-передачи, подтверждая, что претензий к наличию сопровождающих товар документов (инструкция по эксплуатации, гарантийный талон, договор с банком или иной финансовой организацией (в случае приобретения Товара в лизинг или кредит) и т.п.), наименованию, количеству, комплектности, техническим и иным характеристикам Товара не имеет, а также то, что видимых недостатков в Товаре<span > </span>не обнаружено.
                </p>
                <p class="ListParagraph" >
                    <span>5.5.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>После передачи Товара Покупателю претензии к наличию сопровождающих Товар документов (инструкция по эксплуатации, гарантийный талон, договор с банком или иной финансовой организацией (в случае приобретения Товара в лизинг или кредит) и т.п.), наименованию, количеству, комплектности, техническим и иным характеристикам товара не принимаются.
                </p>
            </div>
            <br />
            <div>
                <h3 >
                    6.<span >&#xa0; </span>Способы<span > </span>оплаты<span > </span><span >товара</span>
                </h3>

                <p class="ListParagraph" >
                    <span>6.1.</span><span >&#xa0;&#xa0;&#xa0;&#xa0; </span>Цена Товара указывается рядом с определенным наименованием товара на Сайте в белорусских рублях.
                </p>
                <p class="ListParagraph" >
                    <span>6.2.</span><span >&#xa0;&#xa0;&#xa0;&#xa0; </span>Цены Товаров на Сайте являются динамичными и могут быть изменены Продавцом в одностороннем порядке. При этом цена на Товар, на который оформлен Заказ, изменению не подлежит.
                </p>
                <p class="ListParagraph" >
                    <span>6.3.</span><span >&#xa0;&#xa0;&#xa0;&#xa0; </span>Оплата Товара Покупателем производится путём внесения полной оплаты за Товар в белорусских рублях на расчётный счёт Продавца или через ЕРИП.
                </p>
                <p class="ListParagraph" >
                    <span>6.4.</span><span >&#xa0;&#xa0; </span>Покупатель имеет право воспользоваться услугами банка или иной финансовой организации для оформления кредита или лизинга при покупке Товара у Продавца. Перечень таких банков и финансовых организаций предоставляется Продавцом. Покупатель обязан предоставить информацию, необходимую для проверки кредитной истории Покупателя. Окончательное решение о предоставлении кредита или лизинга<span > </span>принимается банком или<span > </span>иной финансовой<span > </span>организацией. Порядок<span > </span>оплаты товара<span > </span>в случае получения кредита или лизинга определяется заключенным договором между Покупателем и банком или иной финансовой организацией.
                </p>
                <p class="ListParagraph" >
                    <span>6.5.</span><span >&#xa0;&#xa0;&#xa0; </span>Продавец вправе предоставлять Покупателю скидки на Товары. Виды скидок, порядок и условия их предоставления указываются на Сайте в публичном доступе, и могут быть изменены Продавцом в одностороннем порядке.
                </p>
                <p class="BodyText" >
                    &#xa0;
                </p>
                <h3 >
                    7.<span >&#xa0; </span><a id="_._Права_и_обязанности_сторон"></a>Права<span > </span>и<span > </span>обязанности<span > </span><span >сторон</span>
                </h3>
                <p class="ListParagraph" >
                    <span >7.1.</span><span >&#xa0; </span><span >Продавец</span><span > </span><span >обязуется:</span>
                </p>
                <p class="ListParagraph" >
                    <span >7.1.1.</span><span >&#xa0;&#xa0; </span>Обрабатывать персональные данные Покупателя в порядке, установленном действующим законодательством в отношении обработки персональных данных, а также в соответствии с Политикой обработки персональных данных ООО «ЦИТРУСКОМ».
                </p>
                <p class="ListParagraph" >
                    <span >7.1.2.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>С момента заключения Договора добросовестно исполнять обязательства перед Покупателем в соответствии с условиями Договора и в рамках действующего законодательства.
                </p>
                <p class="ListParagraph" >
                    <span >7.2.</span><span >&#xa0; </span><span >Продавец</span><span > </span><span >имеет</span><span > </span><span >право:</span>
                </p>
                <p class="ListParagraph" >
                    <span >7.2.1.</span><span >&#xa0;&#xa0; </span>В одностороннем порядке определять цены Товара, доступные способы и условия оплаты Товара, доступные способы и условия доставок Товара, иные условия приобретения Товара. При этом, новые (измененные) условия вступают в силу с момента размещения на Сайте Интернет-магазина и считаются доведенными до сведения Покупателя путем размещения на Сайте.
                </p>
                <p class="ListParagraph" >
                    <span >7.2.2.</span><span >&#xa0; </span>Осуществлять<span > </span>записи<span > </span>телефонных<span > </span>разговоров<span > </span>с<span > </span><span >Покупателем.</span>
                </p>
                <p class="ListParagraph" >
                    <span >7.2.3.</span><span >&#xa0;&#xa0;&#xa0; </span>Без согласования с Покупателем передавать свои права и обязанности по исполнению обязательств перед Покупателем третьим лицам.
                </p>
                <p class="ListParagraph" >
                    <span >7.2.4.</span><span >&#xa0;&#xa0;&#xa0; </span>В одностороннем порядке отказаться от исполнения обязательств по Договору в случае возникновения<span > </span>обстоятельств непреодолимой силы,<span > </span>обстоятельств, на<span > </span>возникновение<span > </span>которых<span > </span>Продавец не мог повлиять и предвидеть их.
                </p>
                <p class="ListParagraph" >
                    <span >7.2.5.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Продавец имеет право перед доставкой Заказа потребовать от Покупателя 100% предоплаты заказанного Товара.
                </p>
                <p class="BodyText" >
                    Продавец имеет право отказать Покупателю в доставке Заказа при отсутствии такой предоплаты, а в дальнейшем аннулировать Заказ при отсутствии со стороны Покупателя предоплаты в течении 5 календарных дней.
                </p>
                <p class="ListParagraph" >
                    <span >7.2.6.</span><span >&#xa0; </span>Продавец<span > </span>вправе<span > </span>не<span > </span>подтвердить<span > </span>либо<span > </span>аннулировать<span > </span>Заказ<span > </span>и<span > </span>уведомить<span > </span>об<span > </span>этом<span > </span>Покупателя, отказать в оплате и/или продаже Товара по указанной цене, если она явилась следствием технической ошибки или неправомерных действий со стороны третьих лиц.
                </p>
                <p class="ListParagraph" >
                    <span >7.3.</span><span >&#xa0; </span><span >Покупатель</span><span > </span><span >обязуется:</span>
                </p>
                <p class="ListParagraph" >
                    <span >7.3.1.</span><span >&#xa0;&#xa0;&#xa0; </span>До момента заключения Договора ознакомиться с содержанием и условиями настоящей Оферты, содержанием и условиями способов оплаты и доставки, предлагаемых на Сайте.
                </p>
                <p class="ListParagraph" >
                    <span >7.3.2.</span><span >&#xa0;&#xa0; </span>При регистрации (осуществлении Заказа) сообщить все необходимые данные, однозначно идентифицирующие его как Покупателя, и достаточные для исполнения Продавцом обязательств по <span >Договору.</span>
                </p>
                <p class="ListParagraph" >
                    <span ><span >7.3.3.</span></span><span >&#xa0; </span><span >Оплатить</span><span > </span><span >Товар</span><span > </span><span >и</span><span > </span><span >его</span><span > </span><span >доставку</span><span > </span><span >в</span><span > </span><span >соответствии</span><span > </span><span >с</span><span > </span><span >настоящей</span><span > </span><span >Офертой</span><span > </span><span >и</span><span > </span><span >условиями</span><span > </span><span >Заказа.</span>
                </p>
                <p class="ListParagraph" >
                    <span >7.4.</span><span >&#xa0; </span><span >Покупатель</span><span > </span><span >имеет</span><span > </span><span >право </span><span >на:</span>
                </p>
                <p class="ListParagraph" >
                    <span >7.4.1.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Информацию<span > </span>о<span > </span>приобретаемых<span > </span>товарах,<span > </span>а<span > </span>также<span > </span>об<span > </span>их<span > </span>изготовителях<span > </span>(продавцах,<span > </span><span >поставщиках).</span>
                </p>
                <p class="ListParagraph" >
                    <span ><span >7.4.2.</span></span><span >&#xa0; </span><span >Свободный</span><span > </span><span >выбор</span><span > </span><span >товаров.</span>
                </p>
                <p class="ListParagraph" >
                    <span >7.4.3.</span><span >&#xa0; </span><span >Надлежащее</span><span >&#xa0;</span><span >качество</span><span >&#xa0;</span><span >товаров,</span><span >&#xa0;</span><span >в</span><span >&#xa0;</span><span >том</span><span >&#xa0;</span><span >числе</span><span >&#xa0;</span><span >на</span><span >&#xa0;</span><span >их</span><span >&#xa0;</span><span >безопасность,</span><span >&#xa0;</span><span >надлежащую </span>комплектность, надлежащее количество.
                </p>
                <p class="BodyText" >
                    &#xa0;
                </p>
                <h3>
                    8.<span >&#xa0; </span>Условия<span > </span>возврата<span > </span>(обмена)<span > </span>товара.<span > </span>Безвозмездное<span > </span>устранение<span > </span>недостатков <span >товара</span>
                </h3>

                <p class="ListParagraph" >
                    <span >8.1.</span><span >&#xa0; </span><span >Возврат</span><span > </span><span >(обмен)</span><span > </span><span >товара</span><span > </span><span >ненадлежащего</span><span > </span><span >качества:</span>
                </p>
                <p class="ListParagraph" >
                    <span >8.1.1.</span><span >&#xa0;&#xa0;&#xa0;&#xa0; </span>В случае обнаружения недостатков Товара Покупатель вправе предъявить Продавцу требование<span > </span>о замене такого Товара. Требование предъявляется в порядке, определённом в п. 8.4 – п. 8.5 <span >Договора.</span>
                </p>
                <p class="ListParagraph" >
                    <span >8.1.2.</span><span >&#xa0;&#xa0;&#xa0;&#xa0; </span>Возврат (обмен) Товара ненадлежащего качества производится Продавцом в порядке, установленном <span >Законом</span> Республики Беларусь от 09.01.2002 № 90-З «О защите прав потребителей».
                </p>
                <p class="ListParagraph" >
                    <span >8.1.3.</span><span >&#xa0;&#xa0; </span>В случае предъявления требования Покупателем о возврате Товара, Продавец возвращает уплаченные за Товар денежные средства в порядке, предусмотренном п. 9 Договора.
                </p>
                <p class="ListParagraph" >
                    <span >8.2.</span><span >&#xa0; </span><span >Возврат</span><span > </span><span >(обмен)</span><span > </span><span >товара</span><span > </span><span >надлежащего</span><span > </span><span >качества:</span>
                </p>
                <p class="ListParagraph" >
                    <span >8.2.1.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Покупатель вправе в течение четырнадцати дней с момента передачи ему непродовольственного товара, возвратить Товар надлежащего качества или обменять его на аналогичный<span > </span>товар других<span > </span>размера, формы, габарита, фасона, расцветки<span > </span>или комплектации, произведя в случае разницы в цене необходимый перерасчет с продавцом. Возврат Товара надлежащего качества осуществляется силами и за счёт Покупателя. Порядок возврата Товара надлежащего качества согласовывается с Продавцом.
                </p>
                <p class="ListParagraph" >
                    <span >8.2.2.</span><span >&#xa0;&#xa0; </span>Возврат (обмен) товара надлежащего качества может быть произведён при одновременном соблюдении следующих условий:
                </p>
                <p class="ListParagraph" >
                    <span >8.2.2.1.</span><span >&#xa0;&#xa0;&#xa0;&#xa0; </span>Товар не был в употреблении, сохранены его потребительские свойства и имеются доказательства приобретения его у данного продавца. В случае обмена либо возврата товара<span > </span>потребитель обязан возвратить Товар в потребительской упаковке, если Товар был продан в такой <span >упаковке.</span>
                </p>
                <p class="ListParagraph" >
                    <span >8.2.2.2.</span><span >&#xa0;&#xa0; </span>Товар не включён в перечень непродовольственных товаров надлежащего качества, не подлежащих обмену и возврату.
                </p>
                <p class="ListParagraph" >
                    <span >8.2.3.</span><span >&#xa0;&#xa0;&#xa0; </span>При возврате Покупателем Товара надлежащего качества, уплаченная денежная сумма подлежит возврату в порядке, предусмотренном п. 9 Договора в течение семи дней после возврата Товара Продавцу и предъявления требования в порядке, предусмотренном п. 8.4 – п. 8.5 Договора.
                </p>
                <p class="ListParagraph" >
                    <span >8.3.</span><span >&#xa0; </span><span >Безвозмездное</span><span > </span><span >устранение</span><span > </span><span >недостатков</span><span > </span><span >Товара:</span>
                </p>
                <p class="ListParagraph" >
                    <span >8.3.1.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Безвозмездное устранение недостатков Товара производится Продавцом в случае предъявления<span > </span>потребителем требования<span > </span>об устранении недостатков<span > </span>Товара. Требование<span > </span>предъявляется<span > </span>в порядке, определённом в п. 8.4 – п. 8.5 Договора.
                </p>
                <p class="ListParagraph" >
                    <span >8.3.2.</span><span >&#xa0;&#xa0;&#xa0; </span>С момента заключения настоящего Договора Покупатель выражает согласие, что срок безвозмездного устранения недостатков, обнаруженных в Товаре, не может превышать 30 (тридцати) календарных дней со дня предъявления Покупателем такого требования. Требование предъявляется в порядке, определённом в п. 8.4 – п. 8.5 Договора.
                </p>
                <p class="ListParagraph" >
                    <span>8.4.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Требование о возврате (обмене) или безвозмездном устранении недостатков Товара предъявляется Покупателем с указанием:
                </p>
                <p class="ListParagraph" >
                    <span >-</span><span >&#xa0; </span><span >наименования</span><span > </span><span >Товара (в</span><span > </span><span >т.ч.</span><span > </span><span >модель,</span><span > </span><span >марка);</span>
                </p>
                <p class="ListParagraph" >
                    <span >-</span><span >&#xa0; </span><span >даты</span><span > </span><span >приобретения</span><span > </span><span >Товара;</span>
                </p>
                <p class="ListParagraph" >
                    <span >-</span><span >&#xa0; </span><span >способ</span><span > </span><span >приобретения</span><span > </span><span >(собственные</span><span > </span><span >деньги</span><span > </span><span >/</span><span > </span><span >кредит</span><span > </span><span >/</span><span > лизинг);</span>
                </p>
                <p class="ListParagraph" >
                    <span >-</span><span >&#xa0; </span><span >предъявляемое</span><span > </span><span >требование</span><span > </span><span >(возврат</span><span > </span><span >/</span><span > </span><span >обмен</span><span > </span><span >/</span><span > </span><span >безвозмездное</span><span > </span><span >устранение</span><span > </span><span >недостатков);</span>
                </p>
                <p class="ListParagraph" >
                    <span >-</span><span >&#xa0; </span><span >причина</span><span > </span><span >возврата</span><span > </span><span >/</span><span > </span><span >обмена</span><span > </span><span >/</span><span > </span><span >безвозмездного</span><span > </span><span >устранения</span><span > недостатков;</span>
                </p>
                <p class="ListParagraph" >
                    <span >-</span><span >&#xa0; </span><span >контактная</span><span > </span><span >информация</span><span > </span><span >для</span><span > </span><span >обратной связи</span><span > </span><span >(ФИО, контактный</span><span > </span><span >телефон);</span>
                </p>
                <p class="ListParagraph" >
                    <span>-</span><span >&#xa0;&#xa0;&#xa0; </span>счёт<span > </span>в<span > </span>формате<span > </span>IBAN,<span > </span>наименование<span > </span>банка<span > </span>и<span > </span>ФИО<span > </span>владельца<span > </span>счёта<span > </span>(в<span > </span>случае<span > </span>предъявления требования о возврате уплаченных за Товар денежных средств).
                </p>
                <p class="ListParagraph" >
                    <span>8.5.</span><span >&#xa0; </span>Требование<span > </span>считается предъявленным<span > </span>со дня его получения Продавцом<span > </span>одним<span > </span>из следующих <span >способов:</span>
                </p>
                <p class="BodyText" >
                    -<span > </span>по<span > </span>Вайберу:<span > </span>+375-29-341-68-<span >10;</span>
                </p>
                <p class="ListParagraph" >
                    <span >-</span><span >&#xa0; </span><span >по</span><span > </span><span >электронной почте:</span> <a href="mailto:cirtusbuh@gmail.com">cirtusbuh@gmail.com</a>
                </p>
                <p class="BodyText" >
                    -<span > </span>по<span > </span>телефонам:<span > </span>+375-29-314-23-63,<span > </span>+375-29-378-97-19,<span > </span>+375-29-340-90-<span >71;</span>
                </p>
                <p class="ListParagraph" >
                    <span >-</span><span >&#xa0; </span><span >по</span><span > </span><span >почте:</span><span > </span><span >220013,</span><span > </span><span >г.</span><span > </span><span >Минск,</span><span > </span><span >ул.</span><span > </span><span >2-я</span><span > </span><span >Шестая</span><span > </span><span >линия,</span><span > </span><span >11,</span><span > </span><span >каб.</span><span > </span><span >6а-8-</span><span >120.</span>
                </p>
                <p class="ListParagraph" >
                    <span>8.6.</span><span >&#xa0;&#xa0; </span>В случае указания в требовании некорректной или недостоверной информации, Продавец не несёт ответственности за нарушение сроков удовлетворения такого требования.
                </p>
                <p class="BodyText" >
                    &#xa0;
                </p>
                <h3 >
                    9.<span >&#xa0; </span>Возврат<span > </span>денежных<span > </span><span >средств</span>
                </h3>

                <p class="ListParagraph" >
                    <span >9.1.</span><span >&#xa0; </span><span >Возврат</span><span > </span><span >денежных</span><span > </span><span >средств</span><span > </span><span >осуществляется</span><span > </span><span >в</span><span > </span><span >следующих</span><span > </span><span >случаях:</span>
                </p>
                <p class="ListParagraph" >
                    <span >9.1.1.</span><span >&#xa0;&#xa0;&#xa0;&#xa0; </span>В случаях отказа от Товара ввиду обнаружения несоответствия Товара заявленным характеристикам, цене, способам оплаты и иным условиям при приёмке (получении) Товара.
                </p>
                <p class="ListParagraph" >
                    <span >9.1.2.</span><span >&#xa0;&#xa0;&#xa0;&#xa0; </span>В случаях возврата Товара в порядке, предусмотренном пп. 8.1-8.2 и иных случаях, предусмотренных законодательством.
                </p>
                <p class="ListParagraph" >
                    <span>9.2.</span><span >&#xa0;&#xa0; </span>Возврат денежных средств осуществляется в течение 7 дней<span > </span>при соблюдении следующих условий: предъявления требования в порядке, предусмотренном п. 8.4 и п. 8.5 Договора и возврата Товара Продавцу.
                </p>
                <p class="ListParagraph" >
                    <span>9.3.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Продавец не несёт ответственность за просрочку возврата денежных средств, если Покупатель препятствует возврату Товара.
                </p>
                <p class="ListParagraph" >
                    <span>9.4.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Возврат денежных средств Покупателю производится на основании предъявленного требования в порядке, предусмотренном<span > </span>п. 8.4 и п. 8.5 Договора.
                </p>
                <p class="ListParagraph" >
                    <span>9.5.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>В случае приобретения Покупателем Товара в кредит или лизинг, условия возврата уплаченных<span > </span>за Товар денежных<span > </span>средств определяются с учётом требований договора<span > </span>с банком или иной финансовой организацией в следующем порядке:
                </p>
                <p class="ListParagraph" >
                    <span >9.5.1.</span><span >&#xa0;&#xa0;&#xa0; </span>Продавец возвращает банку или иной финансовой организации оставшуюся сумму по кредиту (лизингу).
                </p>
                <p class="ListParagraph" >
                    <span >9.5.2.</span><span >&#xa0; </span>В случае если Покупатель совершал платежи по кредиту (лизингу), то Продавец возмещает Покупателю фактически уплаченную в банк или иную финансовую организацию сумму на основании предъявленного Покупателем требования в порядке, установленном п. 8.4 и п. 8.5 Договора.
                </p>
                <p class="BodyText" >
                    &#xa0;
                </p>
                <h3 >
                    10.<span >&#xa0; </span>Ответственность<span > </span><span >сторон</span>
                </h3>

                <p class="ListParagraph" >
                    <span>10.1.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>За неисполнение или ненадлежащее исполнение условий Договора Стороны несут ответственность в соответствии с законодательством Республики Беларусь и положениями настоящего <span >Договора.</span>
                </p>
                <p class="ListParagraph" >
                    <span>10.2.</span><span >&#xa0;&#xa0; </span>При наступлении обстоятельств невозможности полного или частичного исполнения одной из Сторон обязательств по настоящему Договору, а именно: пожары, стихийное бедствие и иные чрезвычайные<span > </span>ситуации<span > </span>(наводнения,<span > </span>землетрясения,<span > </span>пожары,<span > </span>подтопления,<span > </span>аномально<span > </span>высокие<span > </span>(от
                    +30°С) либо аномально низкие (от -20°С) температуры окружающей среды и другие неблагоприятные условия), любая непроходимость дорог независимо от причин такой непроходимости, забастовки и<span > </span>иные общественные волнения, террористические акты и иные преступные посягательства и др.; войны, военные операции любого характера, блокады, неполадки в городской электросети, в системах телефонной связи или на транзитных узлах сети Интернет и прочие нарушения функционирования<span > </span>сетей передачи данных, находящихся вне сферы влияния Сторон, дорожно-транспортные<span > </span>происшествия;<span > </span>акты<span > </span>законодательства<span > </span>прямо<span > </span>или<span > </span>косвенно<span > </span>запрещающие<span > </span>или<span > </span>препятствующие
                    исполнению обязательств, любые иные обстоятельства, возникшие помимо воли и желания Сторон, которые<span > </span>нельзя предвидеть или избежать, срок<span > </span>исполнения отодвигается соразмерно времени, в течение которого будут действовать такие обстоятельства.
                </p>
                <p class="ListParagraph" >
                    <span>10.3.</span><span >&#xa0;&#xa0; </span>Сторона, для которой возникли указанные в пункте 10.2. Договора обстоятельства, обязана известить другую Сторону не позднее 24 (двадцати четырех) часов с момента возникновения обстоятельств, препятствующих исполнению Стороной принятых на себя обязательств по Договору.
                </p>
                <p class="ListParagraph" >
                    <span>10.4.</span><span >&#xa0;&#xa0;&#xa0;&#xa0; </span>Продавец не несёт ответственности за убытки, причинённые Покупателю вследствие ненадлежащего использования последним Товара, приобретенного в Интернет-магазине Продавца. Ненадлежащим использованием Товара является его использование вне предназначения, нарушение инструкции по эксплуатации.
                </p>
                <p class="ListParagraph" >
                    <span>10.5.</span><span >&#xa0;&#xa0;&#xa0; </span>Покупатель несёт полную ответственность за достоверность идентификационных и иных сведений, указанных им при регистрации в Интернет-магазине и (или) в Заказе.
                </p>
                <p class="ListParagraph" >
                    <span>10.6.</span><span >&#xa0; </span>В<span > </span>случае<span > </span>возникновения<span > </span>вопросов<span > </span>и<span > </span>претензий<span > </span>со стороны<span > </span>Покупателя, он<span > </span>может<span > </span>обратиться к Продавцу по телефону или через форму обратной связи на Сайте Интернет-магазина, а также направить обращение на юридический адрес компании.
                </p>
                <h3 >
                    11.<span >&#xa0; </span>Дополнительные<span > </span><span >условия</span>
                </h3>
                <p class="ListParagraph" >
                    <span>11.1.</span><span >&#xa0;&#xa0;&#xa0; </span>В целях сохранения информации о заказах Покупателя, а также проведения внутренних маркетинговых исследований Покупатель разрешает Продавцу собирать, обрабатывать и хранить информацию<span > </span>о своих<span > </span>персональных данных (в том числе фамилию, имя,<span > </span>отчество, адрес, телефон, адрес электронной почты).
                </p>
                <p class="ListParagraph" >
                    <span>11.2.</span><span >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Продавец обязуется обеспечивать конфиденциальность полученной от Покупателя информации в отношении его персональных данных и в отношении заказов Покупателя в порядке, установленном действующим законодательством Республики Беларусь.
                </p>
                <p class="ListParagraph" >
                    <span>11.3.</span><span >&#xa0;&#xa0;&#xa0; </span>Оставляя персональные данные, Покупатель подтверждает тем самым свое согласие на получение электронных писем и СМС-сообщений, содержащих информацию о новинках, акциях, специальных предложениях и т. д. Покупатель вправе отказаться от получения электронных писем и СМС-сообщений, уведомив об этом Продавца в порядке, предусмотренном Политикой по обработке персональных данных.
                </p>
                <p class="ListParagraph" >
                    <span>11.4.</span><span >&#xa0;&#xa0;&#xa0; </span>Договор считается исполненным Продавцом с момента передачи или доставки Товара в место (по Адресу), указанное (-ому) в Заказе, даже в случае не совпадения фамилии, имени, отчества (при наличии), указанных Покупателем в Заказе, с фамилией, именем, отчеством (при наличии) лица, получающего и<span > </span>принимающего Заказ в<span > </span>месте (Адресе), указанном в Заказе (далее – Получатель Товара). Покупатель наделяет Получателя Товара всеми необходимыми полномочиями и формальностями по получению и приемке Товара, проверке его по количеству, качеству, ассортименту, комплектности (комплекту), упаковке (таре).
                </p>
                <p class="ListParagraph" >
                    <span>11.5.</span><span >&#xa0;&#xa0; </span>Продавец вправе без согласования с Покупателем передавать свои права и обязанности по исполнению Договора третьим лицам.
                </p>
                <p class="BodyText" >
                    &#xa0;
                </p>
                <h3 >
                    12.<span >&#xa0; </span><a id="_2._Реквизиты_продавца"></a>Реквизиты<span > </span><span >продавца</span>
                </h3>
                <p>
                    ООО<span > </span><span >«ЦИТРУСКОМ»</span>
                </p>
                <p>
                    220013,<span > </span>г.<span > </span><span >Минск,</span>
                </p>
                <p>
                    ул.<span > </span>Шестая<span > </span>линия,<span > </span>д.<span > </span>11,<span > </span>каб.<span > </span>6а-8-<span >120</span>
                </p>
                <p>
                    р/с<span > </span>в<span > </span>BYN:<span > </span><span >BY46UNBS30120499610040010933</span>
                </p>
                <p>
                    в<span > </span>ЗАО<span > </span>«БСБбанк» УНП 193699866
                </p>
                <p class="BodyText" >
                    &#xa0;
                </p>
                <p>
                    <span >Телефоны</span><span > </span><span >call-центра:</span>
                </p>
                <p>
                    <span >+375-29-314-23-</span><span >63</span>
                </p>
                <p>
                    <span >+375-29-378-97-</span><span >19</span>
                </p>
                <p>
                    <span >+375-29-340-90-</span><span >71</span>
                </p>
            </div>
        </section>
    </div>
};

export default PublicOffer;
