import React from 'react';
import { useEffect } from 'react';

const About = () => {
    useEffect(() => {
        document.title = 'Цитрусмолл —  О компании';
    }, []);

    return <div className="page">
        <section>
            <h2>О нас</h2>
            <h4>Общество с ограниченной ответственностью "ЦИТРУСКОМ"</h4>
            <p>Зарегистрирован в Мингорисполкоме 25.07.2023 № 193699866</p>
            <p>В торговом реестре с 12.11.2024 № регистрации 733443</p>
            <p>
                <b>Контакты лица, уполномоченного рассматривать обращения покупателей о нарушении их прав:</b>
                <br/>
                <strong>Здоровцова Я.И.</strong>
                <br/>
                +375-29-341-68-10
                <br/>
                E-mail: <a href="mailto:cirtusbuh@gmail.com">cirtusbuh@gmail.com</a>
            </p>
            <p>
                <b>Номер телефона работников местных исполнительных и распорядительных органов по месту государственной регистрации ООО «ЦИТРУСКОМ», уполномоченных рассматривать обращения покупателей:</b>
                <p>+375-17-378-34-12 - администрация Советского района г. Минска</p>
            </p>
            <p>Режим работы интернет-магазина: с <strong>10:00</strong> до <strong>21:00</strong></p>
            <h3>Юридический адрес</h3>
            <p>220013, город Минск,<br />
                улица 2 Шестая Линия,<br />
                дом 11, квартира 6А-8-120</p>

            <h3>Почтовый адрес</h3>
            <p>220013, город Минск,<br />
                улица 2 Шестая Линия,<br />
                дом 11, квартира 6А-8-120</p>

            <h3>Банковские реквизиты</h3>
            <p>БИК: UNBSBY2X<br />
                Р/СЧ: BY46UNBS30120499610040010933<br />
                В ЗАО "БСБ БАНК", г. Минск, Логойский тракт 15-4</p>

            <h3>Склад</h3>
            <p>ул. Чапаева 4А</p>
            <iframe frameBorder={'0'} style={{ marginTop: '10px', width: '100%', minHeight: '250px', borderRadius: '8px' }} src="https://yandex.by/map-widget/v1/-/CDfCjUoV"></iframe>
        </section>
    </div>;
};

export default About;
